<template>
  <div
    class="xueren_phone"
    v-infinite-scroll="getData"
    infinite-scroll-distance="10"
    infinite-scroll-disabled="busy"
  >
    <!-- 面包屑 -->
    <el-row type="flex" justify="center" class="crumbs">
      <el-col >
        <el-breadcrumb separator=">">
          <el-breadcrumb-item :to="{ path: '/' }">社科视频首页</el-breadcrumb-item>
          <el-breadcrumb-item to="/xueren">学人</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <div class="container">
      <el-row type="flex" justify="center">
        <el-col >
          <!-- 副标题 -->
          <ViceHeadline :title="headtion" />

          <el-row :gutter="$store.state.isEquipment == 'pc'?40:0">
            <el-col
              :xs="24"
              :sm="24"
              :md="8"
              :lg="8"
              :xl="8"
              v-for="item in list"
              :key="item.index"
            >
              <!-- <router-link target="_blank" to="/xq"> -->
              <router-link target="_blank"
                :to="{ path: '/xq', query: { qing: item.id, mian:`pho${headtion}` } }"
              >
                <div class="card">
                  <img  v-lazy="item.pub_cover" alt="" class="cover" />
                  <!-- <div class="duration">
                    <p>{{ item.TIME_LENGTH }}</p>
                  </div>
                  <div class="pause">
                    <img src="@/assets/image/zixun/播放.png" alt="" />
                  </div> -->
                  <div class="card_content">
                    <p class="title">{{ item.SYS_TOPIC }}</p>
                    <p class="time">
                      {{ $ModuleDate(item.RECORD_TIME) }}
                    </p>
                  </div>
                </div>
              </router-link>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <div class="loading">
      <p class="busy" v-show="busy">加载中...</p>
      <p class="finish" v-show="finish">没有更多了</p>
    </div>
  </div>
</template>

<script>
import NavType from "@/components/NavType.vue";
import ViceHeadline from "@/components/ListHeadline.vue";
import api from "@/api/index.js";
export default {
  props: {
    msg: String,
  },
  components: {
    ViceHeadline,
    NavType,
  },
  data () {
    return {
      headtion: "",
      pageNo: 1,
      busy: false,
      finish: false,
      disabled: false,
      list: [],
    };
  },
  watch: {
    $route: {
      handler () {
        this.load();
      },
    },
  },
  created () {
    this.getTitle(this.$route.query.id)
  },
  methods: {
    load () {
      var slef = this;
      if (this.pageNo == 1) {
        slef.getData();
      } else {
        this.busy = true;
        setTimeout(function () {
          slef.getData();
        }, 1000);
      }
    },
    getData () {
      if(this.finish){
        return;
      }
      this.busy = true;
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: this.$route.query.id,
          pageNo: this.pageNo,
          pageSize: 9,
        })
        .then((res) => {
          for (var item in res.result) {
            this.list.push(res.result[item]);
          }
          this.busy = false;
          if (res.result.length == 0) {
            this.finish = true;
          } else {
            this.pageNo += 1;
          }
        });
    },
    getTitle (val) {
      this.path = val;
      switch (this.$route.query.id) {
        case "86":
          this.headtion = "共和国学人";
          break;
        case "87":
          this.headtion = "学术百家";
          break;
        case "72":
          this.headtion = "大家印象";
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss">
h3 {
  margin: 2.5rem 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 .625rem;
}

a {
  color: #42b983;
}

.xueren_phone {
  background: #f9f9f9;
  padding:0 0.5rem;
  .crumbs {
    padding-top: 1.25rem;
  }

  .container {
    .card {
      margin-top: 1.5rem;
      background: #ffffff;
      box-shadow: 0px 0px 13px 0px rgba(183, 183, 183, 0.38);
      position: relative;
      cursor: pointer;

      .cover {
        width: 100%;
        // height: 15.5625rem;
      }

      // .duration {
      //   position: absolute;
      //   top: 12.5625rem;
      //   right: 0rem;

      //   p {
      //     font-size: 1rem;
      //     font-weight: 400;
      //     color: #ffffff;
      //     line-height: 3rem;
      //     text-align: right;
      //     padding-right: 1.25rem;
      //     white-space: nowrap;
      //   }
      // }

      // .pause {
      //   position: absolute;
      //   top: 22%;
      //   left: 50%;
      //   transform: translateX(-50%);

      //   img {
      //     width: 5.75rem;
      //     height: 5.75rem;
      //   }
      // }

      .card_content {
        padding: 0.5rem 1rem 1rem 1rem;

        .title {
          font-size: 1.25rem;
          font-weight: bold;
          color: #212121;
          line-height: 2.25rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .time {
          font-size: 1rem;
          font-weight: 400;
          color: #666666;
          line-height: 2rem;
          height:2rem;
        }
      }
    }
  }

  .loading {
    margin: 0.5rem 0;

    p {
      line-height: 2rem;
      font-size: 1rem;
      text-align: center;
      color: #5e6d82;
    }
  }
}
</style>
